import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useManualScrollRestoration = () => {
  const router = useRouter();
  useEffect(() => {
    history.scrollRestoration = 'manual';

    const cachedPageHeight: Array<number> = [];
    const html = document.querySelector('html');

    router.events.on('routeChangeStart', () => {
      cachedPageHeight.push(document.documentElement.offsetHeight);
    });

    router.events.on('routeChangeComplete', () => {
      if (html) {
        html.style.height = 'initial';
      }
    });

    router.beforePopState(() => {
      if (html) {
        html.style.height = `${cachedPageHeight.pop()}px`;
      }
      return true;
    });
  }, []);
};
