import * as React from 'react';

import { HeaderMenuContext } from './HeaderMenuContext';
import { HeaderMenu } from '../../api_entities/strapi/types';

export type HeaderMenuContextProviderProps = {
  children: React.ReactNode;
  strapiData: HeaderMenu;
};

export const HeaderMenuContextProvider = ({
  children,
  strapiData,
}: HeaderMenuContextProviderProps) => {
  return <HeaderMenuContext.Provider value={strapiData}>{children}</HeaderMenuContext.Provider>;
};
