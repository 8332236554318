export const size = {
  mobileS: 320,
  mobileM: 425,
  mobileL: 575,
  tablet: 768,
  laptop: 1024,
  laptopL: 1300,
  desktop: 2560,
} as const;

type SizeName = keyof typeof size;

export const device: { [k in SizeName]: string } = {
  mobileS: `(max-width: ${size.mobileS - 1}px)`,
  mobileM: `(max-width: ${size.mobileM - 1}px)`,
  mobileL: `(max-width: ${size.mobileL - 1}px)`,
  tablet: `(max-width: ${size.tablet - 1}px)`,
  laptop: `(max-width: ${size.laptop - 1}px)`,
  laptopL: `(max-width: ${size.laptopL - 1}px)`,
  desktop: `(max-width: ${size.desktop - 1}px)`,
};
