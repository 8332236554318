import * as React from 'react';
import TagManager from 'react-gtm-module';

export const useGoogleTagManager = () => {
  React.useEffect(() => {
    const gtmId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, []);
};
