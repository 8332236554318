import { useEffect, useState, useCallback, useMemo, FC } from 'react';
import { SocialAuthContext } from './SocialAuthContext';
import { SocialNetworkTokenStateType } from './types';

const googleScope =
  'openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

export const SocialAuthContextProvider: FC = ({ children }) => {
  const [googleClient, setGoogleClient] = useState<google.accounts.oauth2.TokenClient | null>(null);
  const [tokenState, setTokenState] = useState<SocialNetworkTokenStateType>({
    type: 'google',
    token: '',
  });

  const setSocialTokenState = useCallback(
    (state: SocialNetworkTokenStateType) => {
      setTokenState(state);
    },
    []
  );

  useEffect(() => {
    if (typeof window !== undefined) {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || '',
        scope: googleScope,
        callback: async (response) => {
          setTokenState({ type: 'google', token: response.access_token });
        },
      });
      setGoogleClient(client);

      FB.init({
        appId: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
        cookie: true,
        xfbml: true,
        version: 'v17.0',
        autoLogAppEvents: false,
      });
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      tokenState,
      setSocialTokenState,
      googleClient,
    }),
    [tokenState, setSocialTokenState, googleClient]
  );

  return <SocialAuthContext.Provider value={contextValue}>{children}</SocialAuthContext.Provider>;
};
