import axios from 'axios';
import qs from 'qs';
import { logger } from 'utils/logger';
import { HeaderMenu } from './types';
import { STRAPI_URL } from './utils';

const blogUrl = `${STRAPI_URL}/api/header-menu`;

type HeaderMenuResponse = {
  data?: HeaderMenu;
};

export default {
  async fetchData(): Promise<HeaderMenu> {
    try {
      const query = qs.stringify(
        {
          populate: 'deep',
          fields: '*',
        },
        {
          encodeValuesOnly: true,
        }
      );

      const { data } = await axios.get<HeaderMenuResponse>(`${blogUrl}?${query}`);

      const headerMenu = data?.data;

      if (!headerMenu) {
        throw new Error('Header Menu not found.');
      }

      return headerMenu;
    } catch (e: any) {
      logger.error(`Failed to get Header Menu.`, e?.stack);

      throw e;
    }
  },
};
