import { device } from 'constants/device';

import styled from 'styled-components';

export const CookieBannerWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  background-color: ${({ theme }) => theme.brand.blue};

  display: flex;
  padding: 24px 32px;
  @media ${device.laptop} {
    padding: 24px 16px;
  }
  @media ${device.tablet} {
    padding: 8px 16px 16px 16px;
  }
`;

export const CookieBannerContent = styled.div`
  display: flex;

  margin: auto;
  max-width: 1399px;
  @media ${device.tablet} {
    flex-direction: column;
  }
  span {
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.4;
    margin-right: 32px;
    width: calc(100% - 164px);
    @media ${device.tablet} {
      width: 100%;
      font-size: 1.2rem;
    }
  }
  a {
    border-bottom: 1px solid white;
    line-height: 1.2;
    display: inline-block;
  }
  button {
    margin-top: 1px;
    @media ${device.tablet} {
      margin-top: 12px;
      margin-left: auto;
    }
  }
`;
