import { DefaultTheme } from 'styled-components';
import { createTheme } from 'styled-breakpoints';

const defaultBreakpoints = {
  sm: '576px', // device.mobileL
  md: '768px', // device.tablet
  lg: '1024px', // device.laptop
  xl: '1300px', // device.laptopL
  xxl: '2560px', // device.desktop
};

const theme: DefaultTheme = {
  system: {
    black: '#3C3E45',
    borderGray: '#8F969C',
    darkGreyActive: '#898d9e',
    darkGreyNonActive: '#D0D1D8',
    primaryNonActive: '#A7ABBE',
    lightGrey: '#eeeef1',
    light: '#F9F9FA',
    white: '#fff',
    yellow: '#DDDB00',
    red: '#FA4128',
    error: '#E53B3B',
    gray: '#C7C8D0',
    light_purple: '#EDC2F4',
    light_blue: '#C8F2FF',
    disabled: '#E7E7E7',
  },
  brand: {
    blue: '#232D5C',
    darkBlue: '#0F1948',
    lightBlue: '#FAFAFA',
    orange: '#EF7D00',
    darkOrange: '#E07400',
    lightOrange: '#FDF2E5',
  },
  business: {
    green: '#1DBF70',
    cyan: '#083344',
    lightCyan: '#0E7490',
    white: '#FFFFFF',
    lightGray: '#bfb5b4',
    bgGray: '#E6EAEC',
    font: {
      primary: '#232d5c',
    },
    button: {
      solid: {
        secondary: {
          background: '#0e7490',
          color: '#fff',
          hover: {
            background: '#06b6d4',
            color: '#fff',
          },
          disabled: {
            background: '#164e63',
            color: '#97aeb5',
          },
        },
        primary: {
          background: '#1dbf70',
          color: '#fff',
          hover: {
            background: '#1aa460',
            color: '#fff',
          },
          disabled: {
            background: '#008a5c',
            color: '#97aeb5',
          },
        },
      },
      outline: {
        primary: {
          borderColor: '#1dbf70',
          color: '#1dbf70',
          hover: {
            borderColor: '#1aa460',
            color: '#1aa460',
          },
          disabled: {
            borderColor: '#97aeb5',
            color: '#97aeb5',
          },
        },
        secondary: {
          borderColor: '#0e7490',
          color: '#0e7490',
          hover: {
            borderColor: '#06b6d4',
            color: '#06b6d4',
          },
          disabled: {
            borderColor: '#97aeb5',
            color: '#97aeb5',
          },
        },
      },
    },
  },

  dimensions: {
    headerHeight: '96px',
    mobileHeaderHeight: '56px',
    footerHeight: '335px',
    mobileFooterHeight: '320px',
    laptopPageWidth: '925px',
  },
  v2: {
    dimensions: {
      headerHeight: '88px',
      mobileHeaderHeight: '64px',
      footerHeight: '264px',
      mobileFooterHeight: '390px',
      laptopPageWidth: '925px',
    },
  },
  palette: {
    cyan: {
      50: '#ecfeff',
      100: '#cffafe',
      200: '#a5f3fc',
      300: '#67e8f9',
      400: '#22d3ee',
      500: '#06b6d4',
      600: '#0891b2',
      700: '#0e7490',
      800: '#155e75',
      900: '#164e63',
      950: '#083344',
    },
  },
};

export default { ...createTheme(defaultBreakpoints), ...theme };
