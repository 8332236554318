import pino from 'pino';
import * as Sentry from '@sentry/nextjs';

const MSG_KEY = 'payload';

const isObj = (obj: any) => typeof obj === 'object' && obj !== null && !Array.isArray(obj);

export function createLogger({
  level = process.env.NEXT_PUBLIC_LOG_LEVEL,
  version = process.env.npm_package_version,
} = {}): pino.Logger {
  return pino({
    level,
    messageKey: MSG_KEY,
    nestedKey: MSG_KEY,
    hooks: {
      logMethod(input, method) {
        if (input.length === 2) {
          const [message, args] = input;
          if (isObj(args)) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return method.apply(this, [{ message, args }]);
          }
          if (typeof args === 'string') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return method.apply(this, [{ message, args: { context: args } }]);
          }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return method.apply(this, [{ message: input[0] }]);
      },
    },
    formatters: {
      level(label) {
        return { level: label };
      },
      bindings() {
        return {
          ...(version && { version }),
        };
      },
    },
  });
}

export class Logger {
  private _logger: pino.Logger;
  private readonly sentry = Sentry;

  constructor() {
    this._logger = createLogger({
      level: process.env.NEXT_PUBLIC_LOG_LEVEL || 'info',
      ...(process.env.npm_package_version && {
        version: process.env.npm_package_version,
      }),
    });
  }

  error(message: any, stack?: string, context?: any) {
    this._logger.error(message, { stack, context });

    if (this.sentry) {
      this.sentry.captureException(message, { extra: context });
    }
  }

  log(message: any, context?: any) {
    this._logger.info(message, context);
  }

  warn(message: any, context?: any) {
    this._logger.warn(message, context);
  }
}

export const logger = new Logger();
