import { APP_ROUTES } from 'constants/routes';

import { useCookies } from 'react-cookie';
import { useEffect, useState } from 'react';
import { Button } from '@studenten/ui-components';
import Link from 'next/link';

import { CookieBannerContent, CookieBannerWrapper } from './CookieBanner.styled';

const HALF_YEAR_IN_SECONDS = 15768000;
const USER_AGREEMENT_KEY = 'user-cookie-agreement';

export const CookieBanner = () => {
  const [cookies, setCookie] = useCookies([USER_AGREEMENT_KEY]);
  const [isVisible, setVisible] = useState(false);
  const confirmationHandle = () => {
    setCookie(USER_AGREEMENT_KEY, true, {
      expires: new Date(Date.now() + HALF_YEAR_IN_SECONDS * 1000),
    });
  };
  const isAgree = Boolean(cookies[USER_AGREEMENT_KEY]);
  useEffect(() => {
    setVisible(!isAgree);
  }, [isAgree]);

  if (!isVisible) return null;

  return (
    <CookieBannerWrapper>
      <CookieBannerContent>
        <span>
          Wij maken gebruik van cookies om jouw browse-ervaring te verbeteren, gepersonaliseerde
          content te tonen en ons websiteverkeer te analyseren. Zo maken we de website elke dag een
          stukje beter. Door verder te gaan op onze site, stem je in met ons gebruik van cookies.
          Voor meer informatie, lees ons{' '}
          <Link passHref href={APP_ROUTES.PRIVACY_STATEMENT} prefetch={false}>
            <a target="_blank">cookiebeleid</a>
          </Link>
          .
        </span>
        <Button onClick={confirmationHandle} width="140px" height="42px" variant="secondary">
          Oké
        </Button>
      </CookieBannerContent>
    </CookieBannerWrapper>
  );
};
