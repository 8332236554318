import { useEffect } from 'react';
import NextApp, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { Router } from 'next/router';
import Head from 'next/head';
import NProgress from 'nprogress';
import { Hydrate } from 'react-query/hydration';
import globalStyle from 'shared/css/globalStyle';
import theme from 'shared/css/theme';
import { useManualScrollRestoration } from 'features/layout/hooks/useManualScrollRestoration';
import { CookieBanner } from 'features/other/templates/CookieBanner';
import { useGoogleTagManager } from 'features/other/analitycs/google-tag-manager';
import { SocialAuthContextProvider } from '../context/SocialAuthProvider/SocialAuthContextProvider';
import 'shared/css/nprogress.css';
import '@studenten/ui-components/build/index.css';
import 'react-image-crop/dist/ReactCrop.css';
import headerMenu from '../api_entities/strapi/headerMenu';
import { HeaderMenuContextProvider } from '../context/HeaderMenuContext';

if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  const React = require('react');
  const ReactDOM = require('react-dom');
  const axe = require('@axe-core/react');
  axe(React, ReactDOM, 1000);
}

const GlobalStyle = createGlobalStyle`${globalStyle}`;

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 10000,
    },
  },
  queryCache: queryCache,
});

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export type IMyAppInitialProps = {
  menu: any;
};

export type IEnhancedPageProps = {
  dehydratedState?: any;
} & AppProps['pageProps'];

export type IAppProps = AppProps & { pageProps: IEnhancedPageProps };

function MyApp({ Component, pageProps, menu }: IAppProps & IMyAppInitialProps) {
  useGoogleTagManager();
  useManualScrollRestoration();
  useEffect(() => {
    document.body.classList.remove('preload');
    document.body.classList.add('loaded');
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <CookieBanner />
        <QueryClientProvider client={queryClient}>
          <SocialAuthContextProvider>
            <HeaderMenuContextProvider strapiData={menu}>
              <ReactQueryDevtools initialIsOpen={false} />
              <Hydrate state={pageProps.dehydratedState}>
                <Component {...pageProps} />
              </Hydrate>
            </HeaderMenuContextProvider>
          </SocialAuthContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
}

MyApp.getInitialProps = async (
  context: AppContext
): Promise<IMyAppInitialProps & AppInitialProps> => {
  const ctx = await NextApp.getInitialProps(context);
  const data = await headerMenu.fetchData();

  return { ...ctx, menu: data };
};

export default MyApp;
